import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const Footer = () => {
  const theme = useTheme();
  const backgroundColor = theme.palette.primary.main;
  const textColor = 'rgba(255, 255, 255, 0.87)'; // Off-white, slightly grey

  // Retrieve the git hash from the environment variable
  const gitHash = process.env.REACT_APP_GIT_HASH;

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor, // Same color and opacity as the header rectangle
        color: textColor, // Slightly grey off-white text
        textAlign: 'center',
        py: 2, // Padding y-axis (top and bottom)
      }}
    >
      <Typography variant="body2">
        &copy; 2024 Joshua Kallus
      </Typography>
      {gitHash && (
        <Typography variant="body2">
          hash: {gitHash}
        </Typography>
      )}
    </Box>
  );
};

export default Footer;
