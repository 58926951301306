import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';
import PriceTable from './PriceTable';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const Heading = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const TestBox = styled(Paper)({
  backgroundColor: '#f5f5f5', // Slightly darker background
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
  marginBottom: '20px', // Add marginBottom to create space below the box
});

const TestBoxText = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '20px',
  fontFamily: 'Quicksand, sans-serif',
});

const Prices = () => {
  const data = {
    '8.5x11': [
      { frame: 'Premium', mat: 'With Mat', price: '$40.00', cashPrice: '$35.00' },
      { frame: 'Premium', mat: 'Without Mat', price: '$25.00', cashPrice: '$20.00' },
      { frame: 'Standard', mat: 'With Mat', price: '$15.00', cashPrice: '$12.00' },
      { frame: 'Standard', mat: 'Without Mat', price: '$12.00', cashPrice: '$10.00' },
    ],
    '13x19': [
      { frame: 'Premium', mat: 'With Mat', price: '$55.00', cashPrice: '$50.00' },
      { frame: 'Premium', mat: 'Without Mat', price: '$50.00', cashPrice: '$45.00' },
      { frame: 'Standard', mat: 'With Mat', price: '$40.00', cashPrice: '$35.00' },
      { frame: 'Standard', mat: 'Without Mat', price: '$25.00', cashPrice: '$20.00' },
    ],
  };

  return (
    <StyledContainer maxWidth="lg">
      <Heading variant="h1">
        Current Prices and Payment Methods
      </Heading>
      <TestBox>
        <TestBoxText variant="body1">
          Prices for products are listed here. I take payments using Venmo via either your venmo account or with a tap to pay enabled card. I also accept cash and offer a cash discount.
        </TestBoxText>
      </TestBox>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <PriceTable title="8.5x11" data={data['8.5x11']} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PriceTable title="13x19" data={data['13x19']} />
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default Prices;
