import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../home/HomePage';
import ContactInfo from '../contact/ContactInfo';
import Gallery from '../gallery/Gallery';
import Blog from 'components/blog/Blog';
import PostPurchase from 'components/blog/posts/PostPurchase';
import ChoosingPictures from 'components/blog/posts/ChoosingPictures';

const MainView = () => {
  const theme = useTheme();

  return (
    <Box 
      sx={{ 
        width: '100%', 
        height: '100%', 
        flex: 1, 
        padding: 0,
        backgroundColor: theme.palette.background.default, // Use theme background color
      }}
    >
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactInfo />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/postpurchase" element={<PostPurchase/>}/>
        <Route path="/blog/choosingpictures" element={<ChoosingPictures/>}/>
        <Route path="/gallery" element={<Gallery />} />
        {/* Add more routes as needed */}
      </Routes>
    </Box>
  );
};

export default MainView;
