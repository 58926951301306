import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';
import BadAngles from 'assets/images/BadAngles.jpg';
import GoodAngles from 'assets/images/GoodAngles.jpg';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#f5f5f5', // Slightly darker background
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
});

const Heading = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Subheading = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginTop: '1rem',
  marginBottom: '0.5rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Paragraph = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const BoldText = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 'bold',
  fontFamily: 'Quicksand, sans-serif',
  textAlign: 'center',
  marginTop: '20px', // Add space before the bottom text
});

const ImageContainer = styled(Box)({
  textAlign: 'center',
  marginTop: '20px',
  marginBottom: '20px', // Add space between the bottom caption and the bottom line
});

const Image = styled('img')({
  width: '100%',
  maxWidth: '400px',
  borderRadius: '10px',
});

const Caption = styled(Typography)({
  marginTop: '10px',
  fontFamily: 'Quicksand, sans-serif',
  fontSize: '1rem',
  color: '#555',
});

const PostPurchase = () => {
  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper>
        <Heading variant="h1">
          Post-Purchase Tips
        </Heading>
        <Box>        
          <Subheading variant="h2">
            How to Make Your Print Shine
          </Subheading>
          <Paragraph variant="body1">
            Sometimes when you initially hang a printed picture you find it's darker than expected or there is too much glare. Both of these can be resolved with light! Unlike electronic screens which have a backlight to show their colors, printed pictures depend on the available light reflecting off the print to appear bright and colorful.
          </Paragraph>
          <Paragraph variant="body1">
            Careful choice of placement in a room is key to making your art look its best. Hang prints where they are near diffused soft light. You don't want harsh light directed at the print surface as that will result in specular highlights. If there's not enough light available, consider using a picture light to illuminate the print. A carefully placed LED strip can also work.
          </Paragraph>
          <Paragraph variant="body1">
            Glare is an issue with all prints and while it can be mitigated with expensive museum-quality antireflective glass, that may cost hundreds of dollars and you need a custom framer to install. Consider placing your print higher on the wall where there are fewer things to reflect off the plexiglass.
          </Paragraph>
          <ImageContainer>
            <Image src={BadAngles} alt="Image 1 description" />
            <Caption>If you place the frame at head level and theres objects and light sources directly across you will see them reflecting in the glass. Fine details and bright lights can distract from your photo.</Caption>
          </ImageContainer>
          <ImageContainer>
            <Image src={GoodAngles} alt="Image 2 description" />
            <Caption>Hanging it higher on the wall allows a different viewing angle where most of the reflections you see come from your ceiling or high on the opposing wall where there is less likely to be texture and light sources.</Caption>
          </ImageContainer>
          <BoldText variant="body1">
            With these tips you can enjoy your photos in more lighting situations with fewer distractions!
          </BoldText>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

export default PostPurchase;
