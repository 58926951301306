import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#f5f5f5',
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
  border: '1px solid #fff',
});

const InnerPaper = styled(Paper)({
  backgroundColor: '#fff',
  borderRadius: '10px',
  padding: '10px',
  marginTop: '10px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
});

const Heading = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Paragraph = styled(Typography)({
  fontSize: '1.25rem',
  fontFamily: 'Quicksand, sans-serif',
});

const blogPosts = [
  { id: 1, text: 'Check out more info about choosing the best pictures to print here', path: '/choosingpictures' },
  { id: 2, text: 'Read some tips for hanging your new wall art here', path: '/postpurchase' },
];

const OtherInfo = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(`/blog${path}`);
  };

  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper>
        <Heading variant="h1">Other Info</Heading>
        <Box>
          {blogPosts.map((post) => (
            <InnerPaper key={post.id} onClick={() => handleClick(post.path)}>
              <Paragraph variant="body1">
                {post.text}
              </Paragraph>
            </InnerPaper>
          ))}
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

export default OtherInfo;
