import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import '@fontsource/quicksand';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#f5f5f5',
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
});

const StyledHeading = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const StyledAccordionSummary = styled(AccordionSummary)({
  fontSize: '1.25rem',
  fontWeight: 'bold',
  fontFamily: 'Quicksand, sans-serif',
});

const StyledAccordionDetails = styled(AccordionDetails)({
  fontSize: '1.25rem',
  fontFamily: 'Quicksand, sans-serif',
});

const FAQ = () => {
  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper>
        <StyledHeading variant="h1">
          FAQ
        </StyledHeading>
        <Accordion>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            Do you offer prints only?
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            I don't currently offer prints only since they are easily sourced online and providing the print to you undamaged without a frame can be difficult.
          </StyledAccordionDetails>
        </Accordion>
        <Accordion>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            Do you offer other finishes than luster?
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            Currently I only offer luster finish since it looks the best for most prints under glass however if you have other needs such as for black and white photos contact me and we may be able to figure something out.
          </StyledAccordionDetails>
        </Accordion>
        <Accordion>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            Do you offer shipping?
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            To keep costs down I don't offer shipping. Art can only be picked up in Chinatown. Contact me if you intend to order several at once and I may be able to meet you in another location as necessary.
          </StyledAccordionDetails>
        </Accordion>
        <Accordion>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            What are your hours?
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            I am available to meet you in Chinatown most times on Wednesdays and Fridays as well as some times on the weekend. Please contact me if you have any concerns about meeting times.
          </StyledAccordionDetails>
        </Accordion>
        <Accordion>
          <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
            How long do orders take?
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            I aim to have most orders ready the next day however for premium frames it may sometimes take an additional day or two.
          </StyledAccordionDetails>
        </Accordion>
      </StyledPaper>
    </StyledContainer>
  );
};

export default FAQ;
