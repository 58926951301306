import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';
import ProductImage from 'assets/images/Americanflat_hudson_13x19_Zoomed_In.jpg';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#f5f5f5', // Slightly darker background
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
});

const ImageContainer = styled(Box)({
  position: 'relative',
  paddingTop: '66.66%', // 3:2 aspect ratio
});

const Image = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '10px',
});

const Heading = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Paragraph = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const InfoIntro = () => {
  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={5}>
            <ImageContainer>
              <Image
                src={ProductImage}
                alt="Placeholder"
              />
            </ImageContainer>
          </Grid>
          <Grid item xs={12} md={7}>
            <Heading variant="h1">
              Services
            </Heading>
            <Box>
              <Paragraph variant="body1">
                If you have pictures you want printed and framed and don't want to drop hundreds on a custom framing job, Chinatown Prints & Framing can help.
              </Paragraph>
              <Paragraph variant="body1">
                Custom framing shops can be very expensive and may offer higher end products than necessary. Many also require you procure the artwork to be framed yourself.
              </Paragraph>
              <Paragraph variant="body1">
                Chinatown Prints & Framing will print and frame your picture for one price.
              </Paragraph>
              <Paragraph variant="body1">
                We offer 2 sizes of prints: 8.5x11 in and 13x19 in to keep prices down. All pictures are printed on Epson Ultra Premium Luster Photo Paper for a professional look that is detailed, color accurate, and bright.
              </Paragraph>
              <Paragraph variant="body1">
                All prints are framed prior to selling to protect your print and make it easy to hang.
              </Paragraph>
            </Box>
          </Grid>
        </Grid>
      </StyledPaper>
    </StyledContainer>
  );
};

export default InfoIntro;
