import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles'; // Correct import for ThemeProvider
import theme from './theme'; // Import your theme
import ReactGA from 'react-ga4';

// Initialize Google Analytics
ReactGA.initialize('G-CN9ZVC79FP'); // Replace with your Measurement ID

// Track the initial page load
ReactGA.send({ hitType: "pageview", page: window.location.pathname });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}> {/* Pass the theme here */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();