import React from 'react';
import { Container, Typography, Paper, Link, Grid } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '70px', // Increased margin-top to add more space above the container
  marginBottom: '20px',
  maxWidth: '600px', // Set a maximum width to make the container narrower
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#f5f5f5', // Slightly darker background
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
});

const Heading = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const SubHeading = styled(Typography)({
  fontSize: '1.5rem',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Paragraph = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const ContactInfo = () => {
  return (
    <StyledContainer maxWidth={false}> {/* Disable the default maxWidth */}
      <StyledPaper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Heading variant="h4">Contact Us</Heading>
          </Grid>
          <Grid item xs={12}>
            <SubHeading variant="h6">Chinatown Prints & Framing</SubHeading>
            <Paragraph variant="body1">120 Kingston St</Paragraph>
            <Paragraph variant="body1">Boston MA, 02111</Paragraph>
            <Paragraph variant="body1">
              Phone: <Link href="tel:+16176512029" sx={{ textDecoration: 'none', color: 'inherit' }}>+1 (617) 651-2029</Link>
            </Paragraph>
            <Paragraph variant="body1">
              Email: <Link href="mailto:service@chinatownframing.com" sx={{ textDecoration: 'none', color: 'inherit' }}>service@chinatownframing.com</Link>
            </Paragraph>
          </Grid>
        </Grid>
      </StyledPaper>
    </StyledContainer>
  );
};

export default ContactInfo;
