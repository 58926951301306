import React from 'react';
import { Box, useTheme } from '@mui/material';
import Landing from './Landing'; // Adjust the import path as necessary
import InfoIntro from './InfoIntro'; // Adjust the import path as necessary
import Instructions from './Instructions';
import Options from './Options';
import Prices from './Prices';
import FAQ from './FAQ';
import GoogleForm from './GoogleForm';
import FramesGallery from './FramesGallery';
import Links from './Links';

const HomePage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
    >
      <Landing />
      <InfoIntro />
      <Instructions />
      <Options />
      <Prices />
      <FramesGallery />
      <GoogleForm />
      <Links/>
      <FAQ />
    </Box>
  );
};

export default HomePage;
