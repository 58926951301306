import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import panoramaImage from 'assets/images/Gate.jpg';
import '@fontsource/quicksand'; // Importing the Quicksand font

const Header = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: '30vh', // Ensures the header is at least 30% of the viewport height
        textAlign: 'center',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="img"
        src={panoramaImage}
        alt="Panoramic Header"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover', // Ensures the image covers the header area
          objectPosition: 'center', // Ensures the center of the image is maintained
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 0.7))', // Adjust the gradient as needed
          zIndex: 1,
          pointerEvents: 'none', // Ensure the gradient doesn't interfere with interactions
        }}
      />
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          color: '#FFB347',
          textAlign: 'center',
          px: 2, // Add padding to ensure text is not cut off
          width: '100%',
        }}
      >
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: 'Quicksand',
              fontSize: {
                xs: '2.8rem',
                sm: '3.0rem',
                md: '4.0rem',
                lg: '4.5rem',
                xl: '6rem',
              },
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.0)', // Add a subtle shadow for 3D effect
              wordBreak: 'break-word', // Ensure long words break and do not overflow
              color: '#FFB347', // Ensure the text color is applied
            }}
          >
            Chinatown Prints & Framing
          </Typography>
        </Link>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '40px', // Height of the rectangle bar
          backgroundColor: `rgba(${parseInt(theme.palette.primary.main.slice(1, 3), 16)}, ${parseInt(theme.palette.primary.main.slice(3, 5), 16)}, ${parseInt(theme.palette.primary.main.slice(5, 7), 16)}, 0.6)`, // 70% opacity
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            maxWidth: '600px',
          }}
        >
          <Button
            component={Link}
            to="/"
            sx={{
              flex: 1,
              color: '#fff',
              backgroundColor: 'transparent',
              border: 'none',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            Home
          </Button>
          <Button
            component={Link}
            to="/contact"
            sx={{
              flex: 1,
              color: '#fff',
              backgroundColor: 'transparent',
              border: 'none',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            Contact
          </Button>
          <Button
            component={Link}
            to="/blog"
            sx={{
              flex: 1,
              color: '#fff',
              backgroundColor: 'transparent',
              border: 'none',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            Blog
          </Button>
          <Button
            component={Link}
            to="/gallery"
            sx={{
              flex: 1,
              color: '#fff',
              backgroundColor: 'transparent',
              border: 'none',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
            }}
          >
            Gallery
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
