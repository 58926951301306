import React from 'react';
import { Box, Typography } from '@mui/material';
import '@fontsource/quicksand'; // Import the font
import frameImage from 'assets/images/gate_overhead.jpg';

const Landing = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', // Align items to the top
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#f5f5f5', // Light grey background for the entire section
      }}
    >
      <Box
        component="img"
        src={frameImage}
        alt="Landing"
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: { xs: '100vh', sm: 'none' },
          objectFit: 'cover',
          objectPosition: { xs: 'top', sm: 'center' },
          opacity: 1.00, // Slightly reduce the opacity for a more subtle effect
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: { xs: '5%', sm: '10%', md: '15%' }, // Adjust top margin for different screen sizes
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slightly more transparent background
          borderRadius: '16px', // More rounded corners
          padding: { xs: '16px', sm: '24px', md: '32px' }, // Reduced padding
          textAlign: 'center',
          fontFamily: 'Quicksand, sans-serif', // Apply the font family here
          width: { xs: '95%', sm: '80%', md: '60%' }, // Increased width
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for a lifted effect
          border: '1px solid rgba(0, 0, 0, 0.1)', // Light border for definition
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '22px', sm: '26px', md: '30px', lg: '34px' }, // Larger font sizes for better readability
            fontWeight: '500', // Medium weight for better readability
            color: '#333', // Darker text color for better contrast
            lineHeight: 1.5, // Increased line height for better readability
            marginBottom: { xs: '12px', sm: '16px', md: '20px' }, // Margin between sentences
            fontFamily: 'Quicksand, sans-serif', // Apply the font family here as well
          }}
        >
          Chinatown Prints & Framing offers lab quality photo printing and framing services in Boston's Chinatown neighborhood.
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '22px', sm: '26px', md: '30px', lg: '34px' }, // Larger font sizes for better readability
            fontWeight: '500', // Medium weight for better readability
            color: '#333', // Darker text color for better contrast
            lineHeight: 1.5, // Increased line height for better readability
            marginBottom: { xs: '12px', sm: '16px', md: '20px' }, // Margin between sentences
            fontFamily: 'Quicksand, sans-serif', // Apply the font family here as well
          }}
        >
          We keep prices low by offering fixed size prints and frames while providing excellent print quality.
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '22px', sm: '26px', md: '30px', lg: '34px' }, // Larger font sizes for better readability
            fontWeight: '500', // Medium weight for better readability
            color: '#333', // Darker text color for better contrast
            lineHeight: 1.5, // Increased line height for better readability
            fontFamily: 'Quicksand, sans-serif', // Apply the font family here as well
          }}
        >
          Your pictures deserve to be on your wall and we can help make it happen!
        </Typography>
      </Box>
    </Box>
  );
};

export default Landing;
