import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

// Styled components using @mui/system
const StyledTable = styled(Table)({
  minWidth: 300,
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: '#f5f5f5',
});

const StyledTableCell = styled(TableCell)({
  fontFamily: 'Quicksand, sans-serif',
  fontSize: '1rem',
});

const CashPriceCell = styled(StyledTableCell)({
  color: 'red',
});

const PriceTable = ({ title, data }) => {
  return (
    <TableContainer component={Paper}>
      <StyledTable aria-label={`${title} price table`}>
        <StyledTableHead>
          <TableRow>
            <StyledTableCell colSpan={4}>
              {title} Options
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Frame</StyledTableCell>
            <StyledTableCell>Mat Option</StyledTableCell>
            <StyledTableCell>Price</StyledTableCell>
            <CashPriceCell>Cash Price</CashPriceCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <StyledTableCell>{row.frame}</StyledTableCell>
              <StyledTableCell>{row.mat}</StyledTableCell>
              <StyledTableCell>{row.price}</StyledTableCell>
              <CashPriceCell>{row.cashPrice}</CashPriceCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

PriceTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      frame: PropTypes.string.isRequired,
      mat: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      cashPrice: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PriceTable;
