import React from 'react';
import { Container, Typography, Paper, Box, List, ListItem } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';
import AspectRatioDiagram from 'assets/images/AspectRatioDiagram.png'; // Import the image

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#f5f5f5', // Slightly darker background
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
});

const Heading = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Subheading = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginTop: '1rem',
  marginBottom: '0.5rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Paragraph = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const StyledList = styled(List)({
  fontSize: '1.25rem',
  marginBottom: '1rem',
  paddingLeft: '20px',
  fontFamily: 'Quicksand, sans-serif',
});

const StyledListItem = styled(ListItem)({
  display: 'list-item',
  paddingLeft: '20px',
  textIndent: '-20px',
});

const ListItemText = styled(Typography)({
  fontSize: '1.25rem',
  fontFamily: 'Quicksand, sans-serif',
});

const ChoosingPictures = () => {
  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper>
        <Heading variant="h1">
          Choosing Pictures to Print
        </Heading>
        <Box>
          <Paragraph variant="body1">
            All photographers who print their photos know printing shows where you can improve your photography. Any issue in a photo will be more noticeable in printed form than on a screen. The larger you print, the more detail can be shown, which is a double-edged sword. The benefit of this is high-quality images can look stunning when printed correctly and are often more impactful than viewing the same image on a screen.
          </Paragraph>
          <Paragraph variant="body1">
            Ideally, you want to pick an image that is in sharp focus, taken with a steady hand, and is not too bright or too dark. For the best results you should use a high-resolution file. Modern phone cameras are often 12+ megapixels which can be plenty of pixels for a larger 13x19 print. However, if your picture is heavily cropped and under 5 megapixels, it may not be a good candidate to be printed.
          </Paragraph>

          <Subheading variant="h2">
            Recommended Resolutions
          </Subheading>
          <StyledList>
            <StyledListItem>
              <Box>
                <ListItemText>
                  The ideal resolution for an 8.5x11 print is 2550x3300 (8 megapixels)
                </ListItemText>
                <ListItemText>
                  The minimum resolution for an 8.5x11 print is 1700x2200 (4 megapixels)
                </ListItemText>
              </Box>
            </StyledListItem>
            <StyledListItem>
              <Box>
                <ListItemText>
                  The ideal resolution for a 13x19 print is 3900x5700 (22 megapixels)
                </ListItemText>
                <ListItemText>
                  The minimum resolution for a 13x19 print is 2600x3800 (10 megapixels)
                </ListItemText>
              </Box>
            </StyledListItem>
          </StyledList>

          <Paragraph variant="body1">
            Note: There is no harm in using higher resolution files than this when available. If you use a DSLR or mirrorless camera and shoot raw the best format to provide is 16 bit TIFF with no compression and ProPhoto RGB color space. You can export your raw pictures in this format in Adobe Lightroom or other software.
          </Paragraph>

          <Subheading variant="h2">
            Aspect Ratio Considerations
          </Subheading>
          <Paragraph variant="body1">
            Another thing to consider is aspect ratio. Note that all the following prints are assumed to be landscape orientation; however, the same holds true for printing in portrait. A 12-megapixel iPhone camera has an uncropped resolution of 4032x3024 (12 megapixels). This is a 4:3 aspect ratio, which needs some cropping to fit an 8.5x11 or 13x19 print.
          </Paragraph>
          <Box component="img" src={AspectRatioDiagram} alt="Aspect Ratio Diagram" sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }} />
          <Paragraph variant="body1">
            If you want to print at 8.5x11, you need to crop in width to match the aspect ratio. Cropping from 4032 width to 3919 width achieves this and keeps most of the pixels, retaining 11.8 megapixels.
          </Paragraph>
          <Paragraph variant="body1">
            If you try this with 13x19, the numbers change. 13x19 prints have a wider aspect ratio than iPhone photos, so the picture must be cropped in height. Cropping from 3024 to 2758 height achieves this while retaining 11.1 megapixels. If you are starting with an image that has a different aspect ratio, the numbers may change.
          </Paragraph>

          <Subheading variant="h2">
            Cropping and White Space
          </Subheading>
          <Paragraph variant="body1">
            You can provide an image that does not match the aspect ratio for the print you're using, and I will crop it to fill the print. I will use my best judgement for what content is cropped based on the orientation of the photo and its content. If you have a preference, please note it in your request.
          </Paragraph>
          <Paragraph variant="body1">
            If you are certain you do not want any cropping, I can print the image as it is; however, there will be white space in the frame. Please specifically request this if you want it.
          </Paragraph>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

export default ChoosingPictures;
