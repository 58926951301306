import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { styled } from '@mui/system';
import choosingPicturesPhoto from 'assets/images/nemo_grass.jpg';
import postPurchasePhoto from 'assets/images/flowers_zoomed_in.jpg';

import '@fontsource/quicksand';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#f5f5f5', // Slightly darker background
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
  cursor: 'pointer', // Make it clear that the paper is clickable
});

const ImageContainer = styled(Box)({
  position: 'relative',
  paddingTop: '66.66%', // 3:2 aspect ratio
});

const Image = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '10px',
});

const Heading = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const blogs = [
  { id: 1, title: 'Choosing Pictures', image: choosingPicturesPhoto, path: '/choosingpictures' },
  { id: 2, title: 'Post Purchase Tips', image: postPurchasePhoto, path: '/postpurchase' },
];

const Blogs = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(`/blog${path}`);
  };

  return (
    <StyledContainer maxWidth="lg">
      {blogs.map((blog) => (
        <StyledPaper key={blog.id} onClick={() => handleClick(blog.path)}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
              <ImageContainer>
                <Image src={blog.image} alt={blog.title} />
              </ImageContainer>
            </Grid>
            <Grid item xs={12} md={7}>
              <Heading variant="h1">{blog.title}</Heading>
            </Grid>
          </Grid>
        </StyledPaper>
      ))}
    </StyledContainer>
  );
};

export default Blogs;
