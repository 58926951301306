import React from 'react';
import { CssBaseline, Box } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/top-level/Header';
import MainView from './components/top-level/MainView';
import Footer from './components/top-level/Footer';
import ScrollToTop from 'components/util/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        <CssBaseline />
        <Header />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: 0,
          }}
        >
          <MainView />
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
