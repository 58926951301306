import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
  textAlign: 'center',
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#4caf50', // Green background
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
  cursor: 'pointer',
});

const Heading = styled(Typography)({
  fontSize: '2.5rem', // Larger font size
  fontWeight: 'bold',
  fontFamily: 'Quicksand, sans-serif',
  color: '#ffffff', // White text color
});

const GoogleForm = () => {
  const handleClickOpen = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSc3tGGI7BiVug8z7h-o0Z9deS4FAgI5iK79q_pgHuAAilqxJw/viewform?usp=sf_link", '_blank');
  };

  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper onClick={handleClickOpen}>
        <Heading variant="h1">
          Place Order Form
        </Heading>
      </StyledPaper>
    </StyledContainer>
  );
};

export default GoogleForm;
