import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';
import LionStatue from 'assets/images/lion_statue.jpg';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#f5f5f5', // Slightly darker background
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
});

const ImageContainer = styled(Box)({
  position: 'relative',
  paddingTop: '120.48%', // 0.83 aspect ratio (portrait orientation)
});

const Image = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain', // Ensures the image is not cropped
  borderRadius: '10px',
});

const Heading = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Paragraph = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Instructions = () => {
  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={5}>
            <ImageContainer>
              <Image
                src={LionStatue}
                alt="Lion Statue"
              />
            </ImageContainer>
          </Grid>
          <Grid item xs={12} md={7}>
            <Heading variant="h1">
              How it Works
            </Heading>
            <Box>
              <Paragraph variant="body1">
                1. Fill out the form on this site with your printing and framing request.
              </Paragraph>
              <Paragraph variant="body1">
                2. I will email you to confirm your order and address any questions/concerns you have.
              </Paragraph>
              <Paragraph variant="body1">
                3. We schedule a time to meet at Auntie Kay & Uncle Frank Chin Park along the Rose Kennedy Greenway.
              </Paragraph>
              <Paragraph variant="body1">
                4. When we meet I will show you the framed picture there for you to approve and then you can pay either cash or card. The art will then be rewrapped in paper for transportation.
              </Paragraph>
            </Box>
          </Grid>
        </Grid>
      </StyledPaper>
    </StyledContainer>
  );
};

export default Instructions;
