import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#f5f5f5', // Slightly darker background
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
});

const Heading = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Subheading = styled(Typography)({
  fontSize: '1.5rem',
  fontWeight: 'bold',
  marginTop: '1rem',
  marginBottom: '0.5rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Paragraph = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
});

const Guideline = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '1rem',
  fontFamily: 'Quicksand, sans-serif',
  marginLeft: '20px',
});

const Options = () => {
  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper>
        <Heading variant="h1">
          Available Options
        </Heading>
        <Box>
          <Subheading variant="h2">
            Print Sizes
          </Subheading>
          <Paragraph variant="body1">
            Prints are either 8.5x11 or 13x19. The print quality is the same with both sizes however there are some factors to consider when picking a size.
          </Paragraph>
          
          <Subheading variant="h2">
            Framing Style
          </Subheading>
          <Paragraph variant="body1">
            Prints can either be matted or not matted within the frame. A mat is a solid color border that goes between your picture and the frame. A mat can separate your picture from the frame visually and provide a refined professional look. 
            Matted prints require a larger frame to accomodate the mat so they do cost more. When deciding whether to use a mat or not, a useful guideline is:
          </Paragraph>
          <Guideline variant="body1">
            - If the subject of the image is small like people, animals, or small objects, a mat often visually improves the art.
          </Guideline>
          <Guideline variant="body1">
            - For landscape photography or other pictures where the subject/content is physically large, a mat is not as necessary.
          </Guideline>

          <Subheading variant="h2">
            Frame Types
          </Subheading>
          <Paragraph variant="body1">
            We offer standard and premium frames. Standard frames are made from PVC plastic or MDF wood and are sleek with no texture. Premium frames are made with premium wood which shows a grain and has more weight and a better build quality. All frames use polished plexiglass to protect the print.
          </Paragraph>
          <Paragraph variant="body1">
            This decision is entirely personal based on the content of the image and your budget. All mats are white. All frames are black, however, alternative colors are available for premium frames on request.
          </Paragraph>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

export default Options;
