import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';

// Styled components
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const StyledPaper = styled(Paper)({
  backgroundColor: '#f5f5f5',
  borderRadius: '10px',
  padding: '20px',
  marginTop: '20px',
  textAlign: 'center',
});

const ImageBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '20px',
});

const ImageContainer = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '15px',
  cursor: 'zoom-in',
});

const Image = styled('img')({
  width: '100%',
  borderRadius: '15px',
});

const MagnifierGlass = styled(Box)({
  display: 'none',
  position: 'absolute',
  borderRadius: '50%',
  border: '3px solid #000',
  width: '200px',  // Increased size
  height: '200px', // Increased size
  cursor: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '1600%', // Increased zoom level
});

const Caption = styled(Typography)({
  fontSize: '1.25rem',
  marginBottom: '20px',
  fontFamily: 'Quicksand, sans-serif',
});

const NavigationButton = styled(Button)({
  margin: '10px',
});

// Function to dynamically import images and captions from the assets/frames-gallery folder
const importImagesAndCaptions = async () => {
  const context = require.context('assets/images/frames-gallery', true, /\.(png|jpe?g|svg|txt)$/);
  const images = {};

  for (const key of context.keys()) {
    const path = key.split('/');
    const folder = path[1];

    if (!images[folder]) images[folder] = {};

    if (key.includes('left')) {
      images[folder]['left'] = context(key);
    } else if (key.includes('caption')) {
      const response = await fetch(context(key));
      const text = await response.text();
      images[folder]['caption'] = text;
    }
  }

  return images;
};

const FramesGallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadImagesAndCaptions = async () => {
      const loadedImages = await importImagesAndCaptions();
      setImages(loadedImages);
      setLoading(false);
    };

    loadImagesAndCaptions();
  }, []);

  const imageFolders = Object.keys(images);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? imageFolders.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === imageFolders.length - 1 ? 0 : prevIndex + 1));
  };

  const handleMouseMove = (e) => {
    const magnifierGlass = e.currentTarget.querySelector('.magnifier-glass');
    const img = e.currentTarget.querySelector('img');

    const { left, top, width, height } = img.getBoundingClientRect();
    const x = e.pageX - left - window.pageXOffset;
    const y = e.pageY - top - window.pageYOffset;

    const bgPosX = (x / width) * 100;
    const bgPosY = (y / height) * 100;

    magnifierGlass.style.left = `${x - magnifierGlass.offsetWidth / 2}px`;
    magnifierGlass.style.top = `${y - magnifierGlass.offsetHeight / 2}px`;
    magnifierGlass.style.backgroundPosition = `${bgPosX}% ${bgPosY}%`;
    magnifierGlass.style.display = 'block';
  };

  const handleTouchMove = (e) => {
    const magnifierGlass = e.currentTarget.querySelector('.magnifier-glass');
    const img = e.currentTarget.querySelector('img');

    const { left, top, width, height } = img.getBoundingClientRect();
    const touch = e.touches[0];
    const x = touch.pageX - left - window.pageXOffset;
    const y = touch.pageY - top - window.pageYOffset;

    const bgPosX = (x / width) * 100;
    const bgPosY = (y / height) * 100;

    magnifierGlass.style.left = `${x - magnifierGlass.offsetWidth / 2}px`;
    magnifierGlass.style.top = `${y - magnifierGlass.offsetHeight / 2}px`;
    magnifierGlass.style.backgroundPosition = `${bgPosX}% ${bgPosY}%`;
    magnifierGlass.style.display = 'block';
  };

  const handleMouseLeave = (e) => {
    const magnifierGlass = e.currentTarget.querySelector('.magnifier-glass');
    magnifierGlass.style.display = 'none';
  };

  const handleTouchEnd = (e) => {
    const magnifierGlass = e.currentTarget.querySelector('.magnifier-glass');
    magnifierGlass.style.display = 'none';
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <StyledContainer maxWidth="lg">
      <StyledPaper>
        <ImageBox>
          <ImageContainer
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <Image src={images[imageFolders[currentIndex]].left} alt="Left frame" />
            <MagnifierGlass
              className="magnifier-glass"
              style={{ backgroundImage: `url(${images[imageFolders[currentIndex]].left})` }}
            />
          </ImageContainer>
        </ImageBox>
        <Caption variant="body1">
          {images[imageFolders[currentIndex]].caption || `Image ${currentIndex + 1}`}
        </Caption>
        <NavigationButton variant="contained" onClick={handlePrevious}>
          Previous
        </NavigationButton>
        <NavigationButton variant="contained" onClick={handleNext}>
          Next
        </NavigationButton>
      </StyledPaper>
    </StyledContainer>
  );
};

export default FramesGallery;
