import React from 'react';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/quicksand';

// Styled components using @mui/system
const StyledContainer = styled(Container)({
  fontFamily: 'Quicksand, sans-serif',
  marginTop: '20px',
  marginBottom: '20px',
});

const ClickableBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '200px',
  height: '100px',
  margin: '20px auto',
  backgroundColor: '#f5f5f5',
  color: '#000',
  fontSize: '18px',
  fontWeight: 'bold',
  cursor: 'pointer',
  borderRadius: '10px',
  textAlign: 'center',
  fontFamily: 'Quicksand, sans-serif',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.up('md')]: {
    width: '400px',
    height: '150px',
    fontSize: '24px',
  },
}));

const openGallery = () => {
  window.open('https://lightroom.adobe.com/shares/83b37dbb3e3a4d0f9442cdf8ec0eade4', '_blank');
};

const Gallery = () => {
  return (
    <StyledContainer maxWidth="lg">
      <ClickableBox onClick={openGallery}>
        Go to Gallery
      </ClickableBox>
    </StyledContainer>
  );
};

export default Gallery;
